.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out,height 0ms .3s,opacity .3s 0ms;
  width: 100%;
  z-index: 9997;
}

.shepherd-modal-is-visible {
  height: 100%;
  opacity: .5;
  transition: all .3s ease-out,height 0s 0s,opacity .3s 0s;
}

.shepherd-content header {
  background-color: #2c34a8 !important;
  font-family: 'GT America Trial' !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  padding: 1em;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}

.shepherd-title {
  color: white !important;
}

.shepherd-footer {
  padding: 20px !important;
}

.shepherd-button {
  background-color: #23cc7b !important;
  color: #ffffff !important;
  margin-right: 10px !important;
  border: none !important;
  padding: 10px !important;
  font-family: 'GT America Trial' !important;
  font-size: 16px !important;
}

.shepherd-content {
  font-family: 'GT America Trial' !important;
  border-radius: 6px !important;
  max-width: 600px !important;
  z-index: 9999;
  background: #ffffff;
}

.shepherd-cancel-icon {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  box-sizing: none !important;
}

.shepherd-text {
  padding: 20px !important;
  color: rgba(0, 0, 0, 0.75);

}

.shepherd-element {
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  z-index: 9999;
}

.shepherd-element .shepherd-content header .shepherd-title, .shepherd-element .shepherd-content header .shepherd-cancel-link {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
